import React from 'react';
import {
    Autocomplete,
    autocompleteClasses,
    Checkbox,
    Chip,
    createFilterOptions,
    TextField,
    Tooltip
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import {useTheme, styled} from '@mui/material/styles';
import {VariableSizeList} from 'react-window';
import Typography from '@mui/material/Typography';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    let option = dataSet[1];
    let state = dataSet[2];

    return (
        <Tooltip title={option.name} placement="top">
            <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle} sx={{fontSize: 12, px: 0}}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={option.id === -1 ? data.slice(1).every((elem) => elem[2].selected) : state.selected}
                />
                {option.id && option.id !== -1 && <span className={"me-1"}>
                    <Chip variant="contained" size="small" label={option.id}></Chip>
                </span>}
                {option.id.toString() !== option.name.toString() &&
                    <span style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                    {option.name}
                    </span>}
            </Typography>
        </Tooltip>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const MultipleAutocompleteField = ({value, setValue, values, label}) => {
    return (
        <Autocomplete
            multiple
            value={value}
            filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                return [{id: -1, name: 'Select all'}, ...filtered];
            }}
            onChange={(event, value) => {
                if (value.filter(option => option.id && option.id === -1).length === 1) {
                    setValue(value.length === values.length + 1 ? [] : values);
                    return;
                }

                setValue(value);
            }}
            options={values}
            disableListWrap
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            autoHighlight
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name.toString()}
            renderInput={(params) => <TextField {...params} label={label}/>}
            renderOption={(props, option, state) => [props, option, state]}
            sx={{width: 1, mb: 2, fontSize: 2}}
            renderTags={(value, getTagProps) => {
                return <>
                    {value.slice(0, 3).map((option, index) => (
                        <Chip variant="contained" size="small" label={option.name} {...getTagProps({index})} />
                    ))}
                    {value.length > 3 ? `+${value.length - 3}` : ""}
                </>;
            }
            }
            size='small'
        />
    );
};

export default MultipleAutocompleteField;