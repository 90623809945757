import React, {useState} from 'react';
import {Button, Divider} from "@mui/material";
import StackedAreaByMunicipalityTool from "../StackedAreaByMunicipalityTool/StackedAreaByMunicipalityTool";
import StackedAreaByProductTool from "../StackedAreaByProductTool/StackedAreaByProductTool";

const StackedAreaWrapper = ({setConfig, setParams}) => {
    const [groupBy, setGroupBy] = useState('municipality');

    return (
        <div>
            <Divider sx={{my: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>GROUP BY</Divider>
            <Button onClick={() => setGroupBy('municipality')} sx={{mr: 1, fontSize: 10}} size="small"
                    variant={groupBy === 'municipality' ? 'contained' : 'outlined'}>Place</Button>
            <Button onClick={() => setGroupBy('product')} sx={{mr: 1, fontSize: 10}} size="small"
                    variant={groupBy === 'product' ? 'contained' : 'outlined'}>Product</Button>
            <div>
                <Divider sx={{my: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>FILTER BY</Divider>
                {groupBy === 'municipality' &&
                    <StackedAreaByMunicipalityTool setConfig={setConfig} setParams={setParams}></StackedAreaByMunicipalityTool>}
                {groupBy === 'product' &&
                    <StackedAreaByProductTool setConfig={setConfig} setParams={setParams}></StackedAreaByProductTool>}
            </div>
        </div>
    );
};

export default StackedAreaWrapper;