import React, {useEffect, useState} from 'react';
import axios from "../../axios/axios";
import MultipleAutocompleteField from '../MultipleAutocompleteField/MultipleAutocompleteField';
import {sectionColors} from "../../helpers/colors";
import {Alert, Button} from '@mui/material';

const baseConfig = {
    data: [],
    groupBy: [
        'section',
        'hs2_description',
        'hs4_description',
        'hs6_description'
    ],
    sum: 'trade_value',
    depth: 0,
    shapeConfig: {
        fill: d => sectionColors[d['section_id']]
    }
};

const TreeMapByMunicipalityTool = ({setConfig, setParams}) => {
    const [filterBy, setFilterBy] = useState('planning-region');

    const [planningRegions, setPlanningRegions] = useState([]);
    const [selectedPlanningRegions, setSelectedPlanningRegions] = useState([{id: 5, name: 'Пелагониски регион'}]);

    const [municipalities, setMunicipalities] = useState([]);
    const [selectedMunicipalities, setSelectedMunicipalities] = useState([{id: 40, name: 'Битола'}]);

    const [years, setYears] = useState([]);
    const [selectedYears, setSelectedYears] = useState([{id: 2016, name: 2016}]);

    const [errorMessage, setErrorMessage] = useState(null);

    const fetchData = () => {
        if (filterBy === 'planning-region' && selectedPlanningRegions.length === 0) {
            setErrorMessage('Please select a planning region.');
            return;
        }
        if (filterBy === 'municipality' && selectedMunicipalities.length === 0) {
            setErrorMessage('Please select a municipality.')
            return;
        }
        if (selectedYears.length === 0) {
            setErrorMessage('Please select a year.');
            return;
        }
        setErrorMessage(null);

        setConfig({...baseConfig});
        if (filterBy === 'planning-region') {
            setParams({
                url: '/export-records/by-planning-region-and-year',
                config: {
                    params: {
                        planning_region_ids: selectedPlanningRegions.map(planningRegion => planningRegion.id).join(','),
                        years: selectedYears.map(year => year.name).join(',')
                    }
                }
            });
        } else if (filterBy === 'municipality') {
            setParams({
                url: '/export-records/by-municipality-and-year',
                config: {
                    params: {
                        municipality_ids: selectedMunicipalities.map(municipality => municipality.id).join(','),
                        years: selectedYears.map(year => year.name).join(',')
                    }
                }
            });
        }
    };

    useEffect(() => {
        axios.get('/planning-regions')
            .then((response) => {
                setPlanningRegions(response.data);
            });

        axios.get('/municipalities')
            .then((response) => {
                setMunicipalities(response.data);
            });

        axios.get('/years')
            .then((response) => {
                setYears(response.data);
            });

        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [filterBy]);

    return (
        <form>
            <div>
                <Button onClick={() => setFilterBy('planning-region')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'planning-region' ? 'contained' : 'outlined'} size="small">Planning
                    region</Button>
                <Button onClick={() => setFilterBy('municipality')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'municipality' ? 'contained' : 'outlined'}
                        size="small">Municipality</Button>
            </div>
            <div className={"my-3"}>
                {filterBy === 'planning-region' &&
                    <MultipleAutocompleteField value={selectedPlanningRegions} setValue={setSelectedPlanningRegions}
                                               values={planningRegions}
                                               label={"Planning region"}></MultipleAutocompleteField>}
                {filterBy === 'municipality' &&
                    <MultipleAutocompleteField value={selectedMunicipalities} setValue={setSelectedMunicipalities}
                                               values={municipalities}
                                               label={"Municipality"}></MultipleAutocompleteField>}
                <MultipleAutocompleteField value={selectedYears} setValue={setSelectedYears} values={years}
                                           label={"Year"}></MultipleAutocompleteField>
                <Button variant={"contained"} className={"w-100"} onClick={() => fetchData()} sx={{fontSize: 10}}
                        size="small">Display</Button>
                {errorMessage && <Alert severity="error" className={"my-3"}>{errorMessage}</Alert>}
            </div>
        </form>
    );
};

export default TreeMapByMunicipalityTool;