import React from 'react';
import {Geomap} from "d3plus-react";
import {CircularProgress} from "@mui/material";


const GeoMapTool = ({config, loading = false}) => {
    return (
        <div className={"d-flex justify-content-center align-items-center h-100"}>
            {loading && <CircularProgress/>}
            {!loading && <Geomap config={config}/>}
        </div>
    );
};

export default GeoMapTool;