import React from 'react';
import banner from '../../images/macedonia.jpg';

const Home = () => {
    return (
        <div className={"flex-grow-1"}>
            <div style={{
                backgroundImage: `url(${banner})`,
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }} className={"d-flex justify-content-center"}>
                <div style={{paddingTop: '100px'}}>
                    <div style={{backgroundColor: 'rgb(248, 249, 250)'}}
                         className={"px-5 py-3 mx-3 text-center rounded-5 display-1"}>
                        <span style={{color: '#71172D'}}>FIN</span>
                        <span style={{color: '#BF9B30'}}>DATA </span>
                        <span style={{color: '#71172D'}}>MACEDONIA</span>
                    </div>
                    <p className={"m-3 text-center text-white display-6"}>The place to explore public finance data of
                        Macedonia!</p>
                </div>
            </div>
        </div>
    );
};

export default Home;