import React from 'react';
import {CircularProgress} from "@mui/material";
import {StackedArea} from "d3plus-react";

const StackedAreaTool = ({config, loading}) => {
    return (
        <div className={"d-flex justify-content-center align-items-center h-100"}>
            {loading && <CircularProgress/>}
            {!loading && <StackedArea config={config}/>}
        </div>
    );
};

export default StackedAreaTool;