import React, {useState} from 'react';
import GraphTool from "../GraphTool/GraphTool";
import graphHS2 from "./graph_hs2.json";
import graphHS4 from "./graph_hs4.json";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

const NetworksDashboard = () => {
    const [level, setLevel] = useState('4');

    return (
        <div className={"h-100"} style={{position: "relative"}}>
            <div style={{position: "absolute", top: 0, left: 0, zIndex: "10"}}>
                <ToggleButtonGroup
                    color="primary"
                    value={level}
                    exclusive
                    sx={{m: 1}}
                    size="small"
                    onChange={(event, value) => {
                        if (value)
                            setLevel(value);
                    }}>
                    <ToggleButton value="2">HS2</ToggleButton>
                    <ToggleButton value="4">HS4</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {level === '2' && <GraphTool graphData={graphHS2}></GraphTool>}
            {level === '4' && <GraphTool graphData={graphHS4}></GraphTool>}
        </div>
    );
};

export default NetworksDashboard;