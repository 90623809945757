import {useEffect, useState} from "react";
import {MultiDirectedGraph} from "graphology";
import {
    SigmaContainer,
    ControlsContainer,
    ZoomControl,
    FullScreenControl,
    SearchControl,
    useLoadGraph,
    useRegisterEvents,
    useSigma
} from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";

import {EdgeCurvedArrowProgram} from "@sigma/edge-curve";
import {sectionColors} from "../../helpers/colors";

const settings = {
    allowInvalidContainer: true,
    defaultEdgeType: "curvedArrow",
    edgeProgramClasses: {
        curvedArrow: EdgeCurvedArrowProgram
    },
    labelDensity: 0.07,
    labelGridCellSize: 200,
    labelRenderedSizeThreshold: 15,
    labelFont: "Montserrat, sans-serif",
};

const Graph = ({graphData}) => {
    const loadGraph = useLoadGraph();
    const registerEvents = useRegisterEvents();
    const sigma = useSigma();
    const [draggedNode, setDraggedNode] = useState(null);

    useEffect(() => {
        const graph = new MultiDirectedGraph();
        graph.import(graphData);

        for (let node of graph.nodes()) {
            let section = graph.getNodeAttribute(node, "section");
            graph.setNodeAttribute(node, "color", sectionColors[section]);
        }

        loadGraph(graph);

        registerEvents({
            enterNode: (event) => {
                if (!draggedNode) {
                    const graph = sigma.getGraph();
                    graph.setNodeAttribute(event.node, "highlighted", true);
                    for (let node of graph.nodes()) {
                        if (node === event.node || graph.neighbors(event.node).includes(node)) {
                            graph.setNodeAttribute(node, "highlighted", true);
                        } else {
                            graph.setNodeAttribute(node, "highlighted", false);
                            graph.setNodeAttribute(node, "color", "#E2E2E2");
                        }
                    }
                    for (let edge of graph.edges()) {
                        if (graph.extremities(edge).includes(event.node)) {
                            graph.setEdgeAttribute(edge, "size", 3);
                        }
                    }
                }
            }, leaveNode: (event) => {
                if (!draggedNode) {
                    const graph = sigma.getGraph();
                    graph.setNodeAttribute(event.node, "highlighted", false);
                    for (let node of graph.nodes()) {
                        let section = graph.getNodeAttribute(node, "section");
                        graph.setNodeAttribute(node, "color", sectionColors[section]);
                        graph.setNodeAttribute(node, "highlighted", false);
                    }
                    for (let edge of graph.edges()) {
                        graph.setEdgeAttribute(edge, "size", 1);
                        graph.setEdgeAttribute(edge, "hidden", false);
                    }
                }
            }, downNode: (event) => {
                setDraggedNode(event.node);
            }, mousemovebody: (e) => {
                if (!draggedNode) return;
                const pos = sigma.viewportToGraph(e);
                sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x);
                sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y);
                e.preventSigmaDefault();
                e.original.preventDefault();
                e.original.stopPropagation();
            }, mouseup: () => {
                if (draggedNode) setDraggedNode(null);
            }, mousedown: () => {
                if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
            },
        });
    }, [loadGraph, registerEvents, sigma, draggedNode]);

    return null;
};

const GraphTool = ({graphData}) => {
    return (
        <SigmaContainer settings={settings}>
            <Graph graphData={graphData}/>
            <ControlsContainer position={"bottom-right"}>
                <ZoomControl/>
                <FullScreenControl/>
            </ControlsContainer>
            <ControlsContainer position={"top-right"} className={"m-1"}>
                <SearchControl style={{width: "200px"}}/>
            </ControlsContainer>
        </SigmaContainer>
    );
};

export default GraphTool;