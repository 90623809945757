import React, {useEffect, useState} from 'react';
import axios from "../../axios/axios";
import MultipleAutocompleteField from "../MultipleAutocompleteField/MultipleAutocompleteField";
import {Alert, Button, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {formatAbbreviate} from "d3plus-format";
import {sectionColors} from "../../helpers/colors";

const StackedAreaByMunicipalityTool = ({setConfig, setParams}) => {
    const [filterBy, setFilterBy] = useState('planning-region');

    const [planningRegions, setPlanningRegions] = useState([]);
    const [selectedPlanningRegions, setSelectedPlanningRegions] = useState([{id: 5, name: 'Пелагониски регион'}]);

    const [municipalities, setMunicipalities] = useState([]);
    const [selectedMunicipalities, setSelectedMunicipalities] = useState([{id: 40, name: 'Битола'}]);

    const [hierarchicalLevel, setHierarchicalLevel] = useState('2');

    const [errorMessage, setErrorMessage] = useState(null);

    const fetchData = () => {
        if (filterBy === 'planning-region' && selectedPlanningRegions.length === 0) {
            setErrorMessage('Please select a planning region.');
            return;
        }
        if (filterBy === 'municipality' && selectedMunicipalities.length === 0) {
            setErrorMessage('Please select a municipality.')
            return;
        }
        setErrorMessage(null);

        setConfig({
            data: [],
            groupBy: ['section', `hs${hierarchicalLevel}_description`],
            x: 'year',
            xConfig: {title: 'Year'},
            y: 'trade_value',
            yConfig: {title: 'Trade Value'},
            tooltipConfig: {
                tbody: (d, i, x) => {
                    let tbodyData = [];
                    if (!Array.isArray(d['hs2_id']) && hierarchicalLevel === '2') tbodyData.push(['HS2', d['hs2_id']]);
                    if (!Array.isArray(d['hs4_id']) && hierarchicalLevel === '4') tbodyData.push(['HS4', d['hs4_id']]);
                    if (!Array.isArray(d['hs6_id']) && hierarchicalLevel === '6') tbodyData.push(['HS6', d['hs6_id']]);
                    if (Array.isArray(d['year']))
                        tbodyData.push(['Year', d['year'].join(', ')]);
                    else
                        tbodyData.push(['Year', d['year']]);
                    if (d['trade_value']) {
                        tbodyData.push(['Trade Value', `€${formatAbbreviate(d['trade_value'])}`]);
                    }

                    return tbodyData;
                }
            },
            shapeConfig: {
                fill: d => sectionColors[d['section_id']]
            }
        });

        if (filterBy === 'planning-region') {
            setParams({
                url: `/export-records/by-planning-region-and-hs${hierarchicalLevel}`,
                config: {
                    params: {
                        planning_region_ids: selectedPlanningRegions.map(planningRegion => planningRegion.id).join(','),
                    }
                }
            });
        } else if (filterBy === 'municipality') {
            setParams({
                url: `/export-records/by-municipality-and-hs${hierarchicalLevel}`,
                config: {
                    params: {
                        municipality_ids: selectedMunicipalities.map(municipality => municipality.id).join(','),
                    }
                }
            });
        }
    };

    useEffect(() => {
        axios.get('/planning-regions')
            .then((response) => {
                setPlanningRegions(response.data);
            });

        axios.get('/municipalities')
            .then((response) => {
                setMunicipalities(response.data);
            });

        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [filterBy]);

    return (
        <form>
            <div>
                <Button onClick={() => setFilterBy('planning-region')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'planning-region' ? 'contained' : 'outlined'} size="small">Planning
                    region</Button>
                <Button onClick={() => setFilterBy('municipality')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'municipality' ? 'contained' : 'outlined'}
                        size="small">Municipality</Button>
            </div>
            <div className={"my-3"}>
                {filterBy === 'planning-region' &&
                    <MultipleAutocompleteField value={selectedPlanningRegions} setValue={setSelectedPlanningRegions}
                                               values={planningRegions}
                                               label={"Planning region"}></MultipleAutocompleteField>}
                {filterBy === 'municipality' &&
                    <MultipleAutocompleteField value={selectedMunicipalities} setValue={setSelectedMunicipalities}
                                               values={municipalities}
                                               label={"Municipality"}></MultipleAutocompleteField>}
                <ToggleButtonGroup
                    color="primary"
                    value={hierarchicalLevel}
                    exclusive
                    sx={{mt: 1, mb: 2}}
                    size="small"
                    onChange={(event, value) => setHierarchicalLevel(value)}>
                    <ToggleButton value="2">HS2</ToggleButton>
                    <ToggleButton value="4">HS4</ToggleButton>
                    <ToggleButton value="6">HS6</ToggleButton>
                </ToggleButtonGroup>
                <Button variant={"contained"} className={"w-100"} onClick={() => fetchData()} sx={{fontSize: 10}}
                        size="small">Display</Button>
                {errorMessage && <Alert severity="error" className={"my-3"}>{errorMessage}</Alert>}
            </div>
        </form>
    );
};

export default StackedAreaByMunicipalityTool;