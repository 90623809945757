import React, {useEffect, useState} from 'react';
import axios from "../../axios/axios";
import {formatAbbreviate} from "d3plus-format";
import MultipleAutocompleteField from "../MultipleAutocompleteField/MultipleAutocompleteField";
import {Alert, Button} from "@mui/material";
import {planningRegionsColors,} from "../../helpers/colors";

const StackedAreaByProductTool = ({setConfig, setParams}) => {
    const [filterBy, setFilterBy] = useState('section');

    const [sections, setSections] = useState([]);
    const [selectedSections, setSelectedSections] = useState([{id: 1, name: 'Animal Products'}]);

    const [hs2Codes, setHs2Codes] = useState([]);
    const [selectedHs2Codes, setSelectedHs2Codes] = useState([{id: '01', name: 'Live animals'}]);

    const [hs4Codes, setHs4Codes] = useState([]);
    const [selectedHs4Codes, setSelectedHs4Codes] = useState([{id: '0101', name: 'Horses'}]);

    const [hs6Codes, setHs6Codes] = useState([]);
    const [selectedHs6Codes, setSelectedHs6Codes] = useState([{
        id: '010110',
        name: 'Live horses/asses/mules/hinnies: pure-bred breeding animals'
    }]);

    const [errorMessage, setErrorMessage] = useState(null);

    const fetchData = () => {
        if (filterBy === 'section' && selectedSections.length === 0) {
            setErrorMessage('Please select a section.');
            return;
        }
        if (filterBy === 'hs2' && selectedHs2Codes.length === 0) {
            setErrorMessage('Please select a HS2 code.');
            return;
        }
        if (filterBy === 'hs4' && selectedHs4Codes.length === 0) {
            setErrorMessage('Please select a HS4 code.');
            return;
        }
        if (filterBy === 'hs6' && selectedHs6Codes.length === 0) {
            setErrorMessage('Please select a HS6 code.');
            return;
        }
        setErrorMessage(null);

        setConfig({
            data: [],
            groupBy: [
                'planning_region',
                'municipality'
            ],
            x: 'year',
            xConfig: {title: 'Year'},
            y: 'trade_value',
            yConfig: {title: 'Trade Value'},
            tooltipConfig: {
                tbody: (d, i, x) => {
                    let tbodyData = [];
                    if (Array.isArray(d['year']))
                        tbodyData.push(['Year', d['year'].join(', ')]);
                    else
                        tbodyData.push(['Year', d['year']]);
                    if (d['trade_value']) {
                        tbodyData.push(["Trade Value", `€${formatAbbreviate(d['trade_value'])}`]);
                    }

                    return tbodyData;
                }
            },
            shapeConfig: {
                fill: d => planningRegionsColors[d['planning_region_id']]
            }
        });

        if (filterBy === 'section') {
            setParams({
                url: '/export-records/by-section',
                config: {
                    params: {
                        section_ids: selectedSections.map(section => section.id).join(',')
                    }
                }
            });
        } else if (filterBy === 'hs2') {
            setParams({
                url: '/export-records/by-hs2',
                config: {
                    params: {
                        hs2_ids: selectedHs2Codes.map(hs2Code => hs2Code.id).join(',')
                    }
                }
            });
        } else if (filterBy === 'hs4') {
            setParams({
                url: '/export-records/by-hs4',
                config: {
                    params: {
                        hs4_ids: selectedHs4Codes.map(hs4Code => hs4Code.id).join(',')
                    }
                }
            });
        } else if (filterBy === 'hs6') {
            setParams({
                url: '/export-records/by-hs6',
                config: {
                    params: {
                        hs6_ids: selectedHs6Codes.map(hs6Code => hs6Code.id).join(',')
                    }
                }
            });
        }
    };

    useEffect(() => {
        axios.get('/harmonized-classification/sections')
            .then((response) => {
                setSections(response.data);
            });

        axios.get('/harmonized-classification/hs2')
            .then((response) => {
                setHs2Codes(response.data);
            });

        axios.get('/harmonized-classification/hs4')
            .then((response) => {
                setHs4Codes(response.data);
            });

        axios.get('/harmonized-classification/hs6')
            .then((response) => {
                setHs6Codes(response.data);
            });

        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [filterBy]);

    return (
        <form>
            <div>
                <Button onClick={() => setFilterBy('section')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'section' ? 'contained' : 'outlined'} size="small">Section</Button>
                <Button onClick={() => setFilterBy('hs2')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'hs2' ? 'contained' : 'outlined'}
                        size="small">HS2</Button>
                <Button onClick={() => setFilterBy('hs4')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'hs4' ? 'contained' : 'outlined'}
                        size="small">HS4</Button>
                <Button onClick={() => setFilterBy('hs6')} sx={{mr: 1, mb: 1, fontSize: 10}}
                        variant={filterBy === 'hs6' ? 'contained' : 'outlined'}
                        size="small">HS6</Button>
            </div>
            <div className={"my-3"}>
                {filterBy === 'section' &&
                    <MultipleAutocompleteField value={selectedSections} setValue={setSelectedSections}
                                               values={sections}
                                               label={"Section"}></MultipleAutocompleteField>}
                {filterBy === 'hs2' &&
                    <MultipleAutocompleteField value={selectedHs2Codes} setValue={setSelectedHs2Codes}
                                               values={hs2Codes}
                                               label={"HS2"}></MultipleAutocompleteField>}
                {filterBy === 'hs4' &&
                    <MultipleAutocompleteField value={selectedHs4Codes} setValue={setSelectedHs4Codes}
                                               values={hs4Codes}
                                               label={"HS4"}></MultipleAutocompleteField>}
                {filterBy === 'hs6' &&
                    <MultipleAutocompleteField value={selectedHs6Codes} setValue={setSelectedHs6Codes}
                                               values={hs6Codes}
                                               label={"HS6"}></MultipleAutocompleteField>}
                <Button variant={"contained"} className={"w-100"} onClick={() => fetchData()} sx={{fontSize: 10}}
                        size="small">Display</Button>
                {errorMessage && <Alert severity="error" className={"my-3"}>{errorMessage}</Alert>}
            </div>
        </form>
    );
};

export default StackedAreaByProductTool;