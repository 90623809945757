export const sectionColors = {
    1: '#FFD8B1', // Animal Products
    2: '#8DB580', // Vegetable Products
    3: '#FFF8DC', // Animal and Vegetable Bi-Products
    4: '#FFA07A', // Foodstuffs
    5: '#C9A0DC', // Mineral Products
    6: '#589b80', // Chemical Products
    7: '#BC8F8F', // Plastics and Rubbers
    8: '#B2BEB5', // Animal Hides
    9: '#C68E54', // Wood Products
    10: '#efb896', // Paper Goods
    11: '#FFC0CB', // Textiles
    12: '#bd5551', // Footwear and Headwear
    13: '#6495ED', // Stone And Glass
    14: '#E6C691', // Precious Metals
    15: '#8E8E8E', // Metals
    16: '#36454F', // Machines
    17: '#6B8E23', // Transportation
    18: '#D2691E', // Instruments
    19: '#556B2F', // Weapons
    20: '#DA70D6', // Miscellaneous
    21: '#E2725B', // Arts and Antiques
    22: '#4169E1' // Unspecified
};

export const planningRegionsColors = {
    1: '#87CEEB', // Скопски регион
    2: '#E6BE8A', // Источен регион
    3: '#FFB7A2', // Југоисточен регион
    4: '#C4C3D0', // Североисточен регион
    5: '#B7D7A0', // Пелагониски регион
    6: '#FF6F61', // Вардарски регион
    7: '#A1A66E', // Југозападен регион
    8: '#915F6D', // Полошки регион
};