import React from 'react';
import VisualizationTools from "../VisualizationTools/VisualizationTools";

const Dashboard = () => {

    return (
        <div className={"d-flex p-3 flex-grow-1"}>
            <VisualizationTools></VisualizationTools>
        </div>
    );
};

export default Dashboard;