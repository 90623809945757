import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import {createTheme, ThemeProvider} from '@mui/material';
import Header from "./components/Header/Header";
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import "bootstrap/dist/js/bootstrap.bundle";
import NetworksDashboard from "./components/NetworksDashboard/NetworksDashboard";

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#71172D'
            },
            secondary: {
                main: '#BF9B30'
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Header></Header>
            <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/visualization-tools" element={<Dashboard></Dashboard>}></Route>
                <Route path="/networks" element={<NetworksDashboard></NetworksDashboard>}></Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
