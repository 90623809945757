import React, {useState} from 'react';
import {Button, Divider} from "@mui/material";
import GeoMapByProductTool from "../GeoMapByProductTool/GeoMapByProductTool";

const GeoMapWrapper = ({setConfig, setParams}) => {
    const [groupBy, setGroupBy] = useState('product');

    return (
        <div>
            <Divider sx={{my: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>GROUP BY</Divider>
            <Button onClick={() => setGroupBy('product')} sx={{mr: 1, fontSize: 10}} size="small"
                    variant={groupBy === 'product' ? 'contained' : 'outlined'}>Product</Button>
            <div>
                <Divider sx={{my: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>FILTER BY</Divider>
                {groupBy === 'product' && <GeoMapByProductTool setConfig={setConfig} setParams={setParams}></GeoMapByProductTool>}
            </div>
        </div>
    );
};

export default GeoMapWrapper;