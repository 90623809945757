import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "@mui/material";

const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary border-bottom border-bottom-5">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand" href="#" style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '1.5rem'}}>
                    <span style={{color: '#71172D'}}>FIN</span>
                    <span style={{color: '#BF9B30'}}>DATA </span>
                    <span style={{color: '#71172D'}}>MACEDONIA</span></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item d-flex">
                            <Link to="/visualization-tools" className="nav-link active" aria-current="page">
                                <Button size={"large"}>Tools</Button>
                            </Link>
                            <Link to="/networks" className="nav-link" aria-current="page">
                                <Button size={"large"}>Networks</Button>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;