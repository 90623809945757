import React, {useEffect, useState} from 'react';
import {Button, Divider} from '@mui/material';
import StackedAreaWrapper from "../StackedAreaWrapper/StackedAreaWrapper";
import GeoMapWrapper from "../GeoMapWrapper/GeoMapWrapper";
import TreeMapWrapper from "../TreeMapWrapper/TreeMapWrapper";
import TreeMapTool from "../TreeMapTool/TreeMapTool";
import StackedAreaTool from "../StackedAreaTool/StackedAreaTool";
import GeoMapTool from "../GeoMapTool/GeoMap";
import axios from "../../axios/axios";

const VisualizationTools = () => {
    const [visualization, setVisualization] = useState('tree-map');
    const [config, setConfig] = useState({});
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);
    const [abortController, setAbortController] = useState(new AbortController());

    useEffect(() => {
        setLoading(true);
        const timeoutId = setTimeout(() => {
            if (Object.keys(params).length !== 0) {
                if (abortController && typeof abortController.abort === 'function') {
                    abortController.abort();
                }

                const newController = new AbortController();
                setAbortController(newController);
                axios.get(params.url, {...params.config, signal: newController.signal})
                    .then((response) => {
                        setConfig(prevConfig => { return {...prevConfig, data: response.data} });
                        setLoading(false);
                    }).catch(console.log);
            }
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
            abortController.abort();
        }
        // eslint-disable-next-line
    }, [params]);

    return (
        <>
            <div className={"row flex-column flex-md-row flex-grow-1"}>
                <div className={"col-12 col-lg-3 col-xxl-2"}>
                    <Divider sx={{mb: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>TOOL</Divider>
                    <Button onClick={() => setVisualization('tree-map')} sx={{mr: 1, mb: 1, fontSize: 10}}
                            variant={visualization === 'tree-map' ? 'contained' : 'outlined'} size="small">Tree Map</Button>
                    <Button onClick={() => setVisualization('stacked-area')} sx={{mr: 1, mb: 1, fontSize: 10}}
                            variant={visualization === 'stacked-area' ? 'contained' : 'outlined'} size="small">Stacked Area</Button>
                    <Button onClick={() => setVisualization('geo-map')} sx={{mr: 1, mb: 1, fontSize: 10}}
                            variant={visualization === 'geo-map' ? 'contained' : 'outlined'} size="small">Map</Button>
                    {visualization === 'tree-map' && <TreeMapWrapper setConfig={setConfig} setParams={setParams}></TreeMapWrapper>}
                    {visualization === 'stacked-area' && <StackedAreaWrapper setConfig={setConfig} setParams={setParams}></StackedAreaWrapper>}
                    {visualization === 'geo-map' && <GeoMapWrapper setConfig={setConfig} setParams={setParams}></GeoMapWrapper>}
                </div>
                <div className={"col-12 col-lg-9 col-xxl-10 flex-grow-1"} style={{minHeight: '500px'}}>
                    {visualization === 'tree-map' && <TreeMapTool config={config} loading={loading}></TreeMapTool>}
                    {visualization === 'stacked-area' && <StackedAreaTool config={config} loading={loading}></StackedAreaTool>}
                    {visualization === 'geo-map' && <GeoMapTool config={config} loading={loading}></GeoMapTool>}
                </div>
            </div>
        </>
    );
};

export default VisualizationTools;