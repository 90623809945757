import React, {useState} from 'react';
import {Button, Divider} from "@mui/material";
import TreeMapByMunicipalityTool from "../TreeМapByMunicipalityTool/TreeМapByMunicipalityTool";
import TreeMapByProductTool from "../TreeMapByProductTool/TreeMapByProductTool";

const TreeMapWrapper = ({setConfig, setParams}) => {
    const [groupBy, setGroupBy] = useState('place');

    return (
        <div>
            <Divider sx={{my: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>GROUP BY</Divider>
            <Button onClick={() => setGroupBy('place')} sx={{mr: 1, fontSize: 10}} size="small"
                    variant={groupBy === 'place' ? 'contained' : 'outlined'}>Place</Button>
            <Button onClick={() => setGroupBy('product')} sx={{mr: 1, fontSize: 10}} size="small"
                    variant={groupBy === 'product' ? 'contained' : 'outlined'}>Product</Button>
            <div>
                <Divider sx={{my: 1, fontSize: 14}} style={{fontFamily: 'Roboto'}}>FILTER BY</Divider>
                {groupBy === 'place' &&
                    <TreeMapByMunicipalityTool setConfig={setConfig} setParams={setParams}></TreeMapByMunicipalityTool>}
                {groupBy === 'product' &&
                    <TreeMapByProductTool setConfig={setConfig} setParams={setParams}></TreeMapByProductTool>}
            </div>
        </div>
    );
};

export default TreeMapWrapper;