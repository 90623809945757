import {formatAbbreviate} from 'd3plus-format';

const globalConfig = {
    aggs: {
        'section_id': d => d[0]['section_id'],
        'planning_region_id': d => d[0]['planning_region_id'],
    },
    legend: true,
    legendPosition: 'bottom',
    tooltipConfig: {
        tbody: (d, i, x) => {
            let tbodyData = [];
            if (x && x[0] && x[0].__data__.depth === 2 && d['hs2_id'] && !Array.isArray(d['hs2_id'])) tbodyData.push(['HS2', d['hs2_id']]);
            if (x && x[0] && x[0].__data__.depth === 3 && d['hs4_id'] && !Array.isArray(d['hs4_id'])) tbodyData.push(['HS4', d['hs4_id']]);
            if (x && x[0] && x[0].__data__.depth === 4 && d['hs6_id'] && !Array.isArray(d['hs6_id'])) tbodyData.push(['HS6', d['hs6_id']]);
            if (x && x[0] && x[0].__data__.share)
                tbodyData.push(['Percent', `${formatAbbreviate(x[0].__data__.share * 100)}%`]);
            if (d['trade_value'])
                tbodyData.push(['Trade Value', `€${formatAbbreviate(d['trade_value'])}`]);
            // if (d['municipality_id']) {
            //     if (Array.isArray(d['municipality']))
            //         tbodyData.push(['Municipality', d['municipality'].join(', ')]);
            //     else
            //         tbodyData.push(['Municipality', d['municipality']]);
            // }
            // if (d['planning_region_id']) {
            //     if (Array.isArray(d['planning_region_id']))
            //         tbodyData.push(['Planning region', d['planning_region'].join(', ')]);
            //     else
            //         tbodyData.push(['Planning region', d['planning_region']]);
            // }
            if (Array.isArray(d['year']))
                tbodyData.push(['Year', d['year'].join(', ')]);
            else
                tbodyData.push(['Year', d['year']]);

            return tbodyData;
        },
        tbodyStyle: {
            'text-align': 'left',
        },
        'width': '300px'
    },
    noDataHTML: function () {
        return `
                <div style="left: 50%; top: 50%; position: absolute; transform: translate(-50%, -50%); font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;">
                    <strong>${this._translate('Нема достапни податоци.')}</strong>
                </div>`;
    }
};

export default globalConfig;