// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}


* {
  box-sizing: border-box !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;;AAGA;EACE,iCAAiC;AACnC","sourcesContent":["html, body {\n  height: 100%\n}\n\n#root {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n\n* {\n  box-sizing: border-box !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
